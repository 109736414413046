<template>
  <div>
    <v-simple-table class="table" v-if="Object.keys(invoices).length > 0">
      <template>
        <thead>
          <tr>
            <th class="table-title"></th>
            <th
              v-for="invoice in invoices"
              :key="invoice.title"
              class="table-cell"
            >{{ invoice.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table-title">Invoiced</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.invoiced.value) }}
              <v-tooltip bottom v-if="invoice.invoiced.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Invoices</h4>
                  <div v-for="text in invoice.invoiced.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="table-title">Monthly</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.monthly.value) }}
              <v-tooltip bottom v-if="invoice.monthly.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Monthly Invoices</h4>
                  <div v-for="text in invoice.monthly.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="table-title">Yearly</td>
            <td
              v-for="invoice in invoices"
              :key="invoice.title"
              class="table-cell"
            >{{ currencyFormat(invoice.yearly.value) }}</td>
          </tr>
          <tr>
            <td class="table-title">Draft Project Invoices</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.draft_project.value) }}
              <v-tooltip bottom v-if="invoice.draft_project.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Draft Invoices</h4>
                  <div v-for="text in invoice.draft_project.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="table-title">Draft Monthly Invoices</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.draft_monthly.value) }}
              <v-tooltip bottom v-if="invoice.draft_monthly.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Draft Monthly Invoices</h4>
                  <div v-for="text in invoice.draft_monthly.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="table-title">Deals Won</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.deals_won.value) }}
              <v-tooltip bottom v-if="invoice.deals_won.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Deals Won</h4>
                  <div v-for="text in invoice.deals_won.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr class="font-weight-bold">
            <td class="table-title">Totals</td>
            <td
              v-for="invoice in invoices"
              :key="invoice.title"
              class="table-cell"
            >{{ currencyFormat(invoice.totals) }}</td>
          </tr>
          <tr>
            <td class="table-title">Targets</td>
            <td
              v-for="invoice in invoices"
              :key="invoice.title"
              class="table-cell"
            >{{ currencyFormat(invoice.targets) }}</td>
          </tr>
          <tr>
            <td class="table-title">+/-</td>
            <td
              v-for="invoice in invoices"
              :key="invoice.title"
              class="table-cell"
              v-bind:class="{ 'positive': invoice.difference.status === 'green', 'acceptable': invoice.difference.status === 'amber', 'negative': invoice.difference.status === 'red' }"
            >{{ invoice.difference.value }}</td>
          </tr>
          <tr>
            <td class="table-title">Client Yes</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.client_yes.value) }}
              <v-tooltip bottom v-if="invoice.client_yes.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Client Yes</h4>
                  <div v-for="text in invoice.client_yes.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="table-title">Active Sales</td>
            <td v-for="invoice in invoices" :key="invoice.title" class="table-cell">
              {{ currencyFormat(invoice.active_sales.value) }}
              <v-tooltip bottom v-if="invoice.active_sales.text.length > 0">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="blue">mdi-information</v-icon>
                </template>
                <div>
                  <h4 class="mb-1">Active Sales</h4>
                  <div v-for="text in invoice.active_sales.text" :key="text">{{ text }}</div>
                </div>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-container v-else>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-center" cols="12">Loading your pipeline 💼</v-col>
        <v-col cols="6">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    invoices() {
      return this.$store.state.pipeline["invoices"];
    }
  },

  methods: {
    currencyFormat: function(input) {
      let value = parseFloat(input);
      value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

      return "£" + value;
    }
  }
};
</script>

<style scoped>
.table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table th,
.table td {
  height: 35px;
}
.table-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 125px;
}
.table-cell.negative {
  background-color: #ff8a80;
  color: #fff;
}
.table tr:hover > td.negative {
  background-color: #ff5252;
}
.table-cell.positive {
  background-color: #aed581;
  color: #fff;
}
.table tr:hover > td.positive {
  background-color: #9ccc65;
}
.table-cell.acceptable {
  background-color: #ffab40;
  color: #fff;
}
.table tr:hover > td.acceptable {
  background-color: #ff9100;
}
.table-title {
  min-width: 200px;
}
</style>