<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Pipeline</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn depressed class="mr-2" v-on="on">
                  {{ rangeText }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="date" type="month">
                <v-select
                  :items="availableMonths"
                  v-model="months"
                  label="Months"
                  outlined
                  hide-details
                  dense
                  class="month-select"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  :loading="pipelineUpdating"
                  v-on:click="updatePipeline"
                >Update</v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn depressed color="accent" :to="{ name: 'module-pipeline-targets' }">
              <v-icon left>mdi-target</v-icon>Edit Targets
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <PipelineTable></PipelineTable>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">{{ snackbar.text }}</v-snackbar>
  </div>
</template>

<script>
import PipelineTable from "./components/PipelineTable.vue";

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateMenu: false,
      months: 6,
      availableMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      pipelineUpdating: false,
      rangeText: "Loading",
      snackbar: {
        open: false,
        text: "Pipeline successfully refreshed",
        timeout: 4000
      }
    };
  },

  components: { PipelineTable },

  mounted() {
    this.getRangeText();
  },

  methods: {
    updatePipeline() {
      const appId = this.$route.params.id;
      const date = this.date;
      const months = this.months;

      this.pipelineUpdating = true;

      this.$store.commit("showHideLoader", true);

      this.$store
        .dispatch("pipeline/update", {
          appId,
          date,
          months
        })
        .then(() => {
          this.pipelineUpdating = false;
          this.dateMenu = false;
          this.snackbar.open = true;
          this.$store.commit("showHideLoader", false);
          this.getRangeText();
        });
    },
    getRangeText() {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      const dates = this.date.split("-");
      const chosenMonth = parseInt(dates[1]);
      let rangeString =
        months[chosenMonth - 1] +
        " " +
        dates[0] +
        " for " +
        this.months +
        " months";

      this.rangeText = rangeString;
    }
  }
};
</script>

<style scoped>
.month-select {
  max-width: 125px;
}
</style>